:root {
  color-scheme: light dark;
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  font-family: Helvetica, Arial, sans-serif;
}

#mainContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}

#root {
  width: 100%;
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
}

#leftPanel canvas, #centerRightPanel canvas {
  width: 100%; 
  height: 100%; 
}

.panel {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#leftPanel, #centerRightPanel {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  flex: 4;
  height: 100vh;
}

#leftPanel {
  position: relative;
}

#rightPanel {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #000000;
  height: 100vh;  
}

.sub-panel {
  position: relative;
  overflow: hidden;
}

#centerTopRightPanel,  #centerBottomRightPanel {
  background-color: #000000;
  display: flex;
}

#floorDiv {
  width: 100%;
  height: 100%;
  background-color: #000000;
}

#floorDiv img {
  width: 100%;
  height: 100%;
  object-fit: contain; 
  display: 'flex';
  justify-content: 'center';
  align-items: 'center'
}

#floorDiv svg {
  width: 100%;
  height: 100%;
}

#svgDiv {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} 

#infoDiv {
  display: flex;
  flex-direction: column;
  height: 100%;  
  padding: 10px;
}

#centerTopRightPanel {
  position: relative;
  height: 53%;
  border: 2px solid #bc1421;
}

#centerBottomRightPanel {
  height: 47%;
  border-right: 2px solid #bc1421;
  border-left: 2px solid #bc1421;
}

#leftTopPanel {
  height: 53%;
  border-bottom: 2px solid #bc1421;
}

#leftBottomPanel {
  height: 47%;
}

#leafletMap {
  height: 100%;
}

.footer {
  bottom: 0;
  color: #707070;
  height: 2em;
  left: 0;
  position: absolute;
  font-size: small;
  text-align: right;
  width:100%;
  padding-right: 2%;
  z-index: 2000;
}

#viewportFrame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border: 5px solid #bc1421;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 5000;
}


#topBanner {
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: #bc1421;
  color: white;
  text-align: left;
  padding-left: 7px;
  line-height: 30px;
  z-index: 5001;
}

#userIcon {
  padding-right: 25px;
}

#selcomLogo {
  padding-left: 7px
}

.position-button:hover {
  cursor: pointer;
}


.footer {
  bottom: 0;
  color: #00b9ff;
  height: 2em;
  left: 0;
  position: absolute;
  font-size: small;
  text-align: right;
  width:100%;
  padding-right: 5px;
  margin-right: 5px;
}

#leftPanel.expandDiv {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
}

#leftPanel.expandDiv #threeMapCanvas {
  width: 100vw;
  height: 100%;
  z-index: 3000;
}

.infoViewer-box {
  background-color: #00b9ff;
  margin-top: 24px;
  border-color: blue; 
  border-radius: 5px;
  border-width: 1px;
  border-style: solid; 
  padding: 5px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2); 
}

.info-content {
  flex-grow: 1;
  /*height: 500px;*/
  overflow-y: auto; 
  padding: 5px;
  margin-top: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}

.info-content::-webkit-scrollbar {
  width: 8px; 
  background-color: #000000; 
}

.info-content::-webkit-scrollbar-thumb {
  background-color: #0197ce; 
  border-radius: 6px; 
  border: 1px solid blue; 
}

.device-detail {
  /*background-color: #00b9ff66;*/
  border-radius: 5px; 
  border: 1px solid inherit; 
  padding: 1px;
  box-shadow: 1 1px 1px rgba(0, 0, 0, 0.1); 
  margin-bottom: 10px; 
  overflow-wrap: break-word; /* Allows long words to break and wrap onto the next line */
  word-wrap: break-word; /* Older syntax for overflow-wrap */
  white-space: normal; /* Ensures that whitespace is handled normally */
  overflow: hidden; /* Hide any overflow not handled by word-break */
}

.device-detail p {
  margin: 8px 0; 
  line-height: 1.5; 
  padding: 0 4px;
}

.expandDiv {
  position: fixed; 
  top: 0;
  left: 0; 
  width: 100vw; 
  height: 100vh; 
  z-index: 3000; 
  /*animation: expansion 3s forwards;*/
}

@keyframes expansion {
  from {width: 100%; height: 100%;}
  to {width: 100vw; height: 100vh;}
}

.shrinkDiv {
  position: relative; 
  width: 100%; 
  height: 100%;
  z-index: 2000; 
  /*animation: shrinking 3s forwards;*/
}


@keyframes shrinking {
  from {width: 100vw; height: 100vh;}
  to {width: 100%; height: 100%;}
}

#gui {
  position: absolute !important; 
  top: 10 !important; 
  margin-top: 30px;
  left: 0 !important; 
  z-index: 3000;
  width: 150px;
  width: fit-content;
}

.controller.boolean {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.controller.boolean .name {
  flex: 1; 
  text-align: left; 
  margin-right: 10px; 
}

.controller.boolean .widget {
  display: flex;
  justify-content: flex-end;
  flex: 0; 
}







